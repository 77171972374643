<template>
  <div>
    <div class="row">

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <secure-image v-if="brand.logo" :id="brand.name + '-logo'" :endpoint="`/v1/brands/${brand.id}/logo`" width="110"></secure-image>
              <div class="mt-3">
                <h4>{{brand.name}}</h4>
              </div>
            </div>
            <hr class="my-4">
            <ul class="list-group list-group-flush mb-2">
              <li v-if="edit" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Name</h6>
                <input class="form-control" style="width: 75%;" type="text" v-model="brand.name">
              </li>
              <li v-if="edit" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0">Logo</h6>
                <input type="file" accept="image/*" ref="file" id="brandLogo" @change="selectFile" class="form-control">
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0"># Sites</h6>
                <span class="text-white">{{brand.SiteCount || "0"}}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                <h6 class="mb-0"># Cameras</h6>
                <span class="text-white">{{brand.CameraCount || "0"}}</span>
              </li>
            </ul>

            <submit-button v-if="edit" :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updating' @click="saveChanges" :text="'Save Changes'"></submit-button>
            <button class="btn btn-sm" :class="{'btn-light': !edit, 'btn-danger': edit}" @click="edit = !edit" style="float: right;">{{ edit ? 'Stop Editing' : 'Edit' }}</button>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SecureImage from '../../../components/SecureImage.vue'
import SubmitButton from '../../../components/SubmitButton.vue';
export default {
  components: { SecureImage, SubmitButton },
  name: 'admin.brands.view',
  props: ['id'],
  data(){
    return {
      brand: {
        name: "",
        logo: ""
      },
      logo: null,
      edit: false,
      updating: false
    }
  },
  mounted(){
    this.loadBrand()
  },
  methods: {
    loadBrand(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/brands/${this.id}`)
      .then(response => {
        this.brand = response.data.brand;
      })
      .catch(error => {
        this.$error("Failed to load brand.", error);
      })
    },
    selectFile(){
      this.logo = this.$refs.file.files[0];
      console.log(this.logo);
    },
    saveChanges(){
      this.updating = true;
      let data = new FormData();
      data.append('name', this.brand.name);
      if(this.logo !== null){
        data.append('logo', this.logo, this.logo_filename);
      }
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/brands/${this.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.$success("Updated brand");
        this.updating = false;
        this.edit = false;
        this.loadBrand();
      })
      .catch(error => {
        this.$error("Failed to update brand.", error);
        this.updating = false;
      })
    }
  },
  computed: {
    logo_filename: function(){
        if(this.logo == null){
            return "Select File";
        }
        if(this.logo == null){
            return "File Selected";
        }
        return this.logo.name;
    },
  }
}
</script>